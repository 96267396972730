import React, { useState } from "react";

const MentorshipForm = () => {
  const [formSubmitted, setformSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setformSubmitted(true))
      .catch((error) => console.error(error));
  };

  if (formSubmitted) {
    return (
      <div className="text-center">
        <h2 className="text-lg sm:text-xl font-bold">
          Your message has been submited successfuly!
        </h2>
        <p className="pt-2">We'll get in touch with you shortly.</p>
      </div>
    );
  }

  return (
    <form
      className="flex flex-col space-y-6"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      name="mentorship"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="mentorship" />
      <div className="">
        <label htmlFor="form-name">
          Name <span className="text-red-600">*</span>
        </label>
        <input
          id="form-name"
          type="text"
          name="name"
          placeholder="Enter your name"
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-email">
          Email <span className="text-red-600">*</span>
        </label>
        <input
          id="form-email"
          type="email"
          name="email"
          placeholder="Enter your email"
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-phone">
          Phone <span className="text-red-600">*</span>
        </label>
        <input
          id="form-phone"
          type="tel"
          name="phone"
          placeholder="Enter your phone number"
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-message">
          Tell me about yourself and your trading journey{" "}
          <span className="text-red-600">*</span>
        </label>
        <textarea
          id="form-message"
          name="message"
          className="form-textarea block w-full h-32 mt-1"
          required
        />
      </div>
      <div className="">
        <span>
          How many years of trading experience do you currently have?{" "}
          <span className="text-red-600">*</span>
        </span>
        <div className="flex flex-col space-y-2 mt-2">
          <label>
            <input
              type="radio"
              name="experience"
              className="form-radio mr-2"
              value="Less than an year"
              required
            />{" "}
            Less than an year
          </label>
          <label>
            <input
              type="radio"
              name="experience"
              id="experience-2-4"
              className="form-radio mr-2"
              value="2-4"
              required
            />{" "}
            2-4
          </label>
          <label>
            <input
              type="radio"
              name="experience"
              className="form-radio mr-2"
              value="4+"
              required
            />{" "}
            4+
          </label>
        </div>
      </div>
      <div className="">
        <span>
          Which of the following do you consider your strengths?{" "}
          <span className="text-red-600">*</span>
        </span>
        <div className="flex flex-col space-y-2 mt-2">
          <label>
            <input
              type="checkbox"
              name="strengths[]"
              className="form-checkbox mr-2"
              value="Bounces/rejects (scalps)"
            />{" "}
            Bounces/rejects (scalps)
          </label>
          <label>
            <input
              type="checkbox"
              name="strengths[]"
              className="form-checkbox mr-2"
              value="Breakouts"
            />{" "}
            Breakouts
          </label>
          <label>
            <input
              type="checkbox"
              name="strengths[]"
              className="form-checkbox mr-2"
              value="Price action reading"
            />{" "}
            Price action reading
          </label>
          <label>
            <input
              type="checkbox"
              name="strengths[]"
              className="form-checkbox mr-2"
              value="Chart patterns"
            />{" "}
            Chart patterns
          </label>
          <label>
            <input
              type="checkbox"
              name="strengths[]"
              className="form-checkbox mr-2"
              value="Level 2 and tape reading"
            />{" "}
            Level 2 and tape reading
          </label>
          <label>
            <input
              type="checkbox"
              name="strengths[]"
              className="form-checkbox mr-2"
              value="Risk management"
            />{" "}
            Risk management
          </label>
        </div>
      </div>
      <div className="">
        <span>
          Which of the following do you consider your weaknesses?{" "}
          <span className="text-red-600">*</span>
        </span>
        <div className="flex flex-col space-y-2 mt-2">
          <label>
            <input
              type="checkbox"
              name="weaknesses[]"
              className="form-checkbox mr-2"
              value="Bounces/rejects (scalps)"
            />{" "}
            Bounces/rejects (scalps)
          </label>
          <label>
            <input
              type="checkbox"
              name="weaknesses[]"
              className="form-checkbox mr-2"
              value="Breakouts"
            />{" "}
            Breakouts
          </label>
          <label>
            <input
              type="checkbox"
              name="weaknesses[]"
              className="form-checkbox mr-2"
              value="Price action reading"
            />{" "}
            Price action reading
          </label>
          <label>
            <input
              type="checkbox"
              name="weaknesses[]"
              className="form-checkbox mr-2"
              value="Chart patterns"
            />{" "}
            Chart patterns
          </label>
          <label>
            <input
              type="checkbox"
              name="weaknesses[]"
              className="form-checkbox mr-2"
              value="Level 2 and tape reading"
            />{" "}
            Level 2 and tape reading
          </label>
          <label>
            <input
              type="checkbox"
              name="weaknesses[]"
              className="form-checkbox mr-2"
              value="Risk management"
            />{" "}
            Risk management
          </label>
        </div>
      </div>
      <div className="">
        <label htmlFor="form-options-understanding">
          How would you rate your current understanding of
          options trading? <span className="text-red-600">*</span>
        </label>
        <input
          id="form-options-understanding"
          type="text"
          name="current_options_understanding"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-traded-before">
          Have you ever traded options before? If yes,
          please provide details of your experience{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-traded-before"
          type="text"
          name="traded_before"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-time">
          How much time are you willing to commit to learning
          and actively trading options?{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-time"
          type="text"
          name="time_commitment"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-primary-objective">
          What is your primary objective for getting involved
          in options trading? (e.g., generate income, portfolio
          diversification, wealth growth, etc.){" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-primary-objective"
          type="text"
          name="primary_objective"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-risk-tolerance">
          What is your risk tolerance level when it comes
          to investing in options?{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-risk-tolerance"
          type="text"
          name="risk_tolerance"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-options-greeks">
          Are you familiar with the concept of options Greeks?
          If yes, please explain.{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-options-greeks"
          type="text"
          name="options_greeks"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-risk-management-approach">
          How do you typically approach risk management in your
          investment decisions?{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-risk-management-approach"
          type="text"
          name="risk_management_approach"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-technical-analysis-experience">
          Are you comfortable with technical analysis
          and chart reading? Please provide details of your
          experience.{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-technical-analysis-experience"
          type="text"
          name="technical_analysis_experience"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-information-updates">
          How do you stay informed about market news
          and events influencing your trading decisions?{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-information-updates"
          type="text"
          name="information_source"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>
      <div className="">
        <label htmlFor="form-budget">
          What is your tarding capital budget for
          options trading?{" "}
          <span className="text-red-600">*</span>
        </label>
        <input
          id="form-budget"
          type="text"
          name="budget"
          placeholder=""
          className="form-input block w-full mt-1"
          required
        />
      </div>

      <div className="mt-6">
        <button
          type="submit"
          className="bg-gray-900 text-gray-100 font-bold rounded-full px-8 py-2 rounded-md text-sm"
        >
          SUBMIT
        </button>
      </div>
    </form>
  );
};

export default MentorshipForm;
