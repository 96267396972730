import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import MentorshipForm from "./partials/mentorshipForm";

const MentorshipPage = () => {
  const { imgAri } = useStaticQuery(
    graphql`
      {
        imgAri: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "header-bg.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Seo title="Mentorship Program" />
      <div className="border-b border-gray-100">
        <div className="main-container">
          <div className="welcome text-center mt-10 sm:mt-24 md:mt-32 sm:mx-10 md:mx-20 mb-20">
            <h1 className="text-4xl sm:text-6xl font-bold font-display uppercase">
              Mentorship Program
            </h1>
            <p className="mt-5 sm:mt-10 text-lg uppercase">
              Learn to trade by signing up for the Mentorship Program. If
              selected, you'll be included in a small group of students and
              participate in the 4-week mentorship program.
            </p>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-100">
        <div className="main-container my-20">
          <div className="text-center text-3xl sm:text-4xl font-bold font-display">
            What's included?
          </div>
          <div className="md:flex md:space-x-8 mt-8">
            <div className="md:flex-1 text-center">
              <GatsbyImage
                alt=""
                image={imgAri.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className="md:flex-1">
              <div className="py-5">
                <h2 className="text-2xl sm:text-3xl font-bold">
                  Weekly live classes
                </h2>
                <p>
                  This will be a 4-week program where live 2-hour sessions will
                  be hosted every weekend to cover an array of advanced trading
                  strategies. At the end of the 4-week program, you will have
                  insight into trading ideology, techniques, and the general
                  know-how of what it will take to be a profitable trader.
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-2xl sm:text-3xl font-bold">
                  One-on-one sessions
                </h2>
                <p>
                  You will be able to schedule one-on-one sessions and get all
                  your trading-related questions answered live.
                </p>
              </div>
              <div className="py-5">
                <h2 className="text-2xl sm:text-3xl font-bold">
                  Access to Pro-Traders chat
                </h2>
                <p>
                  Upon completing the mentorship program, students will be added
                  to the pro-trading server to stay in contact with other
                  students and me on an ongoing basis at no extra cost. Students
                  will have access to this chat as long as they follow the
                  server's rules of conduct.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-100">
        <div className="main-container my-20">
          <div className="text-center">
            <h2 className="text-3xl sm:text-4xl font-bold font-display">
              Apply for the Mentorship Program
            </h2>
            <p className="mt-5 sm:mt-10 text-lg">
              Fill out the application form to introduce yourself, and tell us a
              little about your current trading journey and what you wish to
              gain from this mentorship. Applications will be reviewed, and you
              will be contacted via email if selected.
            </p>
          </div>
          <div className="mx-auto my-16 w-full sm:w-8/12 md:w-6/12">
            <MentorshipForm name="membership" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MentorshipPage;
